<template>
  <div
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div :style="{ position: 'absolute', top: '30px', left: '30px' }">
      <v-img
        v-if="WEBAPPPRO_LOGO_2"
        :src="require(`../../assets/images/${WEBAPPPRO_LOGO_2}`)"
        :width="iconWidth"
      />
    </div>
    <div :style="{ position: 'absolute', top: '30px', right: '30px' }">
      <v-img src="../../assets/images/logo_ants.png" />
    </div>
    <div class="d-flex flex-column container">
      <div class="content-container">
        <h1
          class="text-left mb-7"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_1,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontFamily: WEBAPPPRO_FONT_FAMILY_1,
            fontWeight: '400px',
            marginTop: '80px',
          }"
        >
          Faites votre photo<br />
          d’identité officielle pour <br />votre permis de conduire
        </h1>
        <v-img :src="WEBAPPPRO_CARTE" class="mt-5 mb-5" />
        <v-card
          class="pa-2 pt-5 justify-start align-left"
          :style="{
            backgroundColor: WEBAPPPRO_CARD_COLOR,
            boxShadow: WEBAPPPRO_CARD_SHADOW_1,
            borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
            width: '327px',
            height: '230px',
          }"
          :variant="variant"
        >
          <h1
            class="mx-7"
            :style="{
              color: WEBAPPPRO_TEXT_COLOR_ETAPES,
              fontSize: WEBAPPPRO_FONT_SIZE_2,
              fontFamily: WEBAPPPRO_FONT_FAMILY_1,
              marginBottom: '10px',
              fontWeight: 'regular',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }"
          >
            {{ payByUser ? 3 : 2 }} étapes faciles
          </h1>

          <div
            v-if="
              rejectStatus == undefined || rejectStatus == 'pending_payment'
            "
            class="d-flex flex-column align-left"
          >
            <ul v-for="item in items" :key="item.number" class="mt-1 mb-4">
              <div class="d-flex align-left">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    :fill="WEBAPPPRO_COLOR_1_FILL_ICON"
                  />
                  <path
                    d="M16.0978 7.84636C15.8876 8.06726 15.6797 8.28582 15.4696 8.50672C14.9667 9.03549 14.4637 9.56425 13.9608 10.093C13.3528 10.7322 12.747 11.3691 12.139 12.0083C11.6182 12.5559 11.0951 13.1058 10.5743 13.6533C10.3217 13.9189 10.0647 14.1821 9.81653 14.45C9.80536 14.4618 9.79865 14.4665 9.78524 14.4782C9.86124 14.4171 9.935 14.356 10.011 14.2949C10.0021 14.2996 9.99536 14.302 9.98642 14.3067C10.0758 14.2667 10.1652 14.2268 10.2524 14.1892C10.2435 14.1915 10.2345 14.1915 10.2256 14.1939C10.3239 14.1798 10.4245 14.1657 10.5229 14.1516C10.5139 14.1516 10.505 14.1516 10.4961 14.1516C10.5944 14.1657 10.695 14.1798 10.7934 14.1939C10.7844 14.1915 10.7755 14.1892 10.7665 14.1868C10.856 14.2268 10.9454 14.2667 11.0325 14.3043C11.0236 14.2996 11.0169 14.2949 11.008 14.2926C11.084 14.3537 11.1577 14.4148 11.2337 14.4759C11.0437 14.3161 10.8805 14.1092 10.7084 13.9283C10.3977 13.6016 10.087 13.275 9.7763 12.9483C9.39629 12.5488 9.01853 12.1517 8.63852 11.7521C8.39488 11.496 8.15123 11.2398 7.90981 10.986C7.7064 10.7722 7.40687 10.6429 7.11851 10.6429C6.84357 10.6429 6.51722 10.7698 6.32721 10.986C6.13274 11.2093 5.98745 11.503 6.00086 11.8179C6.01427 12.1305 6.11486 12.4243 6.32721 12.6499C6.90169 13.2538 7.4784 13.8601 8.05287 14.4641C8.48652 14.92 8.92017 15.3759 9.35382 15.8318C9.517 16.0034 9.67794 16.1984 9.87689 16.323C10.3664 16.6285 10.9878 16.5251 11.388 16.1162C11.4058 16.0997 11.4215 16.0809 11.4394 16.0621C12.0384 15.4323 12.6375 14.8025 13.2366 14.1727C14.1553 13.2068 15.0717 12.2433 15.9905 11.2774C16.5426 10.697 17.1014 10.1212 17.6491 9.5337C17.658 9.5243 17.6647 9.51725 17.6736 9.50785C17.8771 9.29399 18 8.97909 18 8.67593C18 8.38687 17.8793 8.04376 17.6736 7.84401C17.4613 7.63956 17.1819 7.4868 16.8823 7.5009C16.5918 7.51735 16.3101 7.62075 16.0978 7.84636Z"
                    fill="white"
                  />
                </svg>
                <strong
                  :style="{
                    color: WEBAPPPRO_TEXT_COLOR_3,
                    fontSize: WEBAPPPRO_FONT_SIZE_3,
                    fontFamily: WEBAPPPRO_FONT_FAMILY_2,
                    marginLeft: '10px',
                    fontWeight: '400',
                  }"
                  >{{ item.title }}</strong
                >
              </div>
            </ul>
          </div>
        </v-card>
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          class="centered-button"
          :disabled="canvasEmpty"
          @click="validate"
          :loading="uploading"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            textTransform: 'none',
            marginTop: '35px',
            marginBottom: '10px',
            padding: '25px 100px',
            fontWeight: 'bold',
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            width: '327px',
            height: '52px',
          }"
        >
          Continuer
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
//import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'Welcome',
  data() {
    return {
      rejectStatus: null,
      photoRejectReasons: null,
      payByUser: true,

      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_3',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      items: [],
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      WEBAPPPRO_LOGO_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_1',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_CARTE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARTE',
      ),
      WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_COLOR',
      ),
      WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1_FILL_ICON',
      ),
      WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_ETAPES',
      ),
    }
  },
  methods: {
    validate() {
      if (this.validatedImages.length > 0 && this.addOffer != null) {
        this.$router.push({
          name: 'Choice',
        })
      } else {
        this.$router.push({ name: 'ShootPhoto' })
      }
    },
    redirectNewOrder() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
  mounted() {
    console.log(localStorageService.getValue('validatedImages'))
    if (localStorageService.getValue('shortliveApiKey') === undefined) {
      this.$router.push({ name: 'NotAuthorized' })
    } else {
      this.rejectStatus = localStorageService.getValue('rejectStatus')
      this.photoRejectReasons =
        localStorageService.getValue('photoRejectReasons')
      this.payByUser = localStorageService.getValue('payByUser')
      this.items = [
        {
          number: 1,
          title: 'La photo',
          description:
            'Prenez votre photo en vous mettant face à la lumière, de préférence sur un fond uni, retirez vos lunettes.',
        },
        {
          number: 2,
          title: 'La signature',
          description:
            'Certifiez votre photo avec une signature de votre doigt à l’intérieur du cadre.',
        },
      ]
      if (this.payByUser) {
        this.items.push({
          number: 3,
          title: 'Le paiement',
          description:
            'Réglez votre commande dans un environnement sécurisé et suivez son traitement.',
        })
      }
    }
    /* const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
        }
      })
    }*/
    /*localStorageService.clearAll()*/
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

.background-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.v-card {
  width: 327px;
}
.mobile-only {
  display: none;
}
.container {
  padding: 0 25px; /* Ajouter des marges aux extrémités */
}
@media screen and (max-width: 600px) {
  .mobile-only {
    display: block;
  }
  .centered-button {
    margin-left: 0;
  }
}
</style>
