var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column overflow-y-auto"},[_c('div',{staticClass:"content-container"},[_c('h1',{staticClass:"text-center mx-5",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_1,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
          marginBottom: '10px',
          fontWeight: 'regular',
          'text-transform': 'uppercase',
        })},[_vm._v(" votre photo a été refusée ")]),_c('p',{staticClass:"ma-5 text-center",style:({
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          color: _vm.WEBAPPPRO_TEXT_COLOR_2,
        })},[_c('strong',{style:({
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
          })},[_vm._v("Vous êtes invité à refaire gratuitement votre photo d’identité,")]),_vm._v(" la photo précédente ne respectant pas "),_c('br'),_vm._v("les normes standards de "),_c('br'),_vm._v(" la photo d’identité. ")]),_c('br'),(_vm.rejectStatus == 'rejected_photo')?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-card',{staticClass:"mt-2 mb-7 mx-auto py-5",style:({
            backgroundColor: _vm.WEBAPPPRO_COLOR_2,
            boxShadow: _vm.WEBAPPPRO_CARD_SHADOW_2,
            borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
          }),attrs:{"width":"327px"}},[_c('div',{staticClass:"px-5",staticStyle:{"display":"flex","align-items":"center"}},[_c('svg',{attrs:{"width":"34","height":"34","viewBox":"0 0 34 34","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"34","height":"34","rx":"8","fill":_vm.WEBAPPPRO_FILL_COLOR_FOR_TREATMENT}}),_c('path',{attrs:{"d":"M14.4586 8C13.9716 8 13.5208 8.24706 13.2643 8.65173L12.0801 10.5274C11.9439 10.7432 11.7033 10.8738 11.4453 10.8738H9.78283C8.2407 10.8454 7 12.1744 7 13.7888V22.085C7 23.6994 8.2407 25 9.78283 25H24.2157C25.7579 25 27 23.6994 27 22.085V13.7888C27 12.1744 25.7579 10.8738 24.2157 10.8738H22.5533C22.2953 10.8738 22.0561 10.7432 21.9199 10.5274L20.7357 8.65173C20.4792 8.24706 20.027 8 19.5414 8H14.4586ZM17.0008 13.5134C19.388 13.5134 21.3402 15.4259 21.3402 17.7643C21.3402 20.1029 19.3879 22.0153 17.0008 22.0153C14.6138 22.0153 12.6615 20.1027 12.6615 17.7643C12.6615 15.4258 14.6138 13.5134 17.0008 13.5134ZM17.0008 15.1519C15.5181 15.1519 14.334 16.3119 14.334 17.7644C14.334 19.217 15.5181 20.377 17.0008 20.377C18.4836 20.377 19.6677 19.217 19.6677 17.7644C19.6677 16.3119 18.4836 15.1519 17.0008 15.1519Z","fill":_vm.WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT}})]),_c('strong',{style:({
                color: _vm.WEBAPPPRO_TEXT_COLOR_1,
                fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
                fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
                marginLeft: '10px',
                'font-weight': 'regular',
                'text-transform': 'uppercase',
              })},[_vm._v("Raisons du refus")])]),_c('div',{staticStyle:{"width":"100%","margin-top":"2px"}},[_c('div',{staticClass:"px-4",style:({
                color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                'font-weight': 'medium',
              })},[_c('ul',_vm._l((_vm.photoRejectReasons),function(rejectItem){return _c('li',{key:rejectItem},[_vm._v(" "+_vm._s(rejectItem.long_text)+" ")])}),0)])])])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-card',{staticClass:"mt-2 mb-7 mx-auto py-5",style:({
            backgroundColor: _vm.WEBAPPPRO_COLOR_1,
            boxShadow: _vm.WEBAPPPRO_CARD_SHADOW_1,
            borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
          }),attrs:{"width":"327px"}},[_c('div',{staticClass:"px-5",staticStyle:{"display":"flex","align-items":"center"}},[_c('svg',{attrs:{"width":"34","height":"34","viewBox":"0 0 34 34","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"34","height":"34","rx":"8","fill":_vm.WEBAPPPRO_FILL_COLOR_FOR_TREATMENT}}),_c('path',{attrs:{"d":"M18.8552 9.07563C18.4655 8.40544 17.7733 8 16.998 8C16.2226 8 15.5304 8.40251 15.1407 9.07563L7.2922 22.6806C6.9026 23.3508 6.9026 24.1509 7.2922 24.824C7.67693 25.4942 8.37215 25.8947 9.14745 25.8947H24.8526C25.6249 25.8947 26.3201 25.4942 26.7078 24.824C27.0974 24.1538 27.0974 23.3537 26.7078 22.6806L18.8593 9.07563H18.8552ZM16.998 22.3985C16.5234 22.3985 16.1377 22.0155 16.1377 21.5407C16.1377 21.0659 16.5224 20.68 16.998 20.68C17.4725 20.68 17.8582 21.063 17.8582 21.5407C17.8582 22.0184 17.4735 22.3985 16.998 22.3985ZM18.0681 15.4837L17.6131 19.368C17.5184 20.0958 16.4707 20.088 16.3808 19.368L15.9258 15.4837C15.8233 13.8903 18.1658 13.8903 18.0681 15.4837Z","fill":_vm.WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT}})]),_c('strong',{style:({
                color: _vm.WEBAPPPRO_TEXT_COLOR_1,
                fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
                fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
                marginLeft: '10px',
                'font-weight': 'regular',
                'text-transform': 'uppercase',
              })},[_vm._v("Attention")])]),_c('div',{staticStyle:{"width":"100%","margin-top":"10px"}},[_c('p',{staticClass:"px-4",style:({
                color: _vm.WEBAPPPRO_TEXT_COLOR_2,
                fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                'font-weight': 'medium',
              })},[_vm._v(" Tant que la photo n’est pas prise correctement, la commande ne pourra pas être validée par nos services de contrôle. ")])])])],1),_c('br'),_c('v-btn',{staticClass:"centered-button mt-2",style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          padding: '25px 100px',
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          marginTop: '20px',
          width: '327px',
          height: '52px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.canvasEmpty,"loading":_vm.uploading},on:{"click":_vm.validate}},[_vm._v("Suivant ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }